








































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { dataSourcesService } from '@/services/DataSourcesService';
import EventBus from '@/EventBus';
import { manualDataConfigService } from '@/services/ManualDataConfigService';
import DataTable from '@/components/common/DataTable.vue';
import ErrorBar from '@/components/common/ErrorBar.vue';
import LoadingBar from '@/components/common/LoadingBar.vue';
import DataFetcher from '@/components/common/DataFetcher.vue';

@Component({
  components: { DataFetcher, LoadingBar, DataTable, Header, ErrorBar },
})
export default class DataSources extends Vue {
  apiUrl = '';
  token = '';
  type = '';
  manualName = '';
  manualFields = '';
  manualType = 1;
  manualData = [];
  manualDataError = false;
  hasAutomaticDataError = false;
  manualDataTableOptions = [
    { field: 'name', label: 'Nazwa' },
    { field: 'fields', label: 'Pola dodatkowe' },
    { field: 'type', label: 'Typ', options: { 1: 'Miesięcznie', 2: 'Rocznie', 3: 'Stałe' } },
  ];
  isLoadingManualList = false;
  isLoadingAutomaticConfiguration = false;
  saveConfiguration() {
    dataSourcesService
      .saveBasicConfiguration(this.apiUrl, this.token)
      .then(() => {
        EventBus.$emit('show-success', 'Zapisano');
      })
      .catch(() => {});
  }
  saveNewManual() {
    manualDataConfigService
      .add(this.manualName, this.manualFields, this.manualType)
      .then(() => {
        EventBus.$emit('show-success', 'Zapisano');
        this.manualName = '';
        this.manualFields = '';
        this.manualType = 1;
      })
      .catch(() => {});
  }
  mounted() {
    this.type = this.$route.params.type || '';
    this.getBasicConfig();
    this.getManualList();
  }
  getBasicConfig() {
    this.hasAutomaticDataError = false;
    this.isLoadingAutomaticConfiguration = true;
    dataSourcesService
      .getBasicConfiguration()
      .then(data => {
        this.isLoadingAutomaticConfiguration = false;
        this.apiUrl = data.apiUrl;
        this.token = data.token;
      })
      .catch(() => {
        this.isLoadingAutomaticConfiguration = false;
        this.hasAutomaticDataError = true;
      });
  }
  getManualList() {
    this.manualDataError = false;
    this.isLoadingManualList = true;
    manualDataConfigService
      .list()
      .then(data => {
        this.manualData = data;
        this.isLoadingManualList = false;
      })
      .catch(() => {
        this.manualData = [];
        this.manualDataError = true;
        this.isLoadingManualList = false;
      });
  }
  goTo(path: string) {
    this.type = path;
    if (path === 'manual-list') {
      this.getManualList();
    }
    if (path === '') {
      this.getBasicConfig();
    }
    this.$router.push(`/data-sources/${path}`).catch(() => {});
  }
}
