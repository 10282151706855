import { httpService } from '@/services/HttpService';

class AutomaticDataFetcherService {
  fetch(month: string): Promise<any> {
    return httpService.get(`automaticData/${month}`, {}, {});
  }
  list(): Promise<any> {
    return httpService.get(`automaticData`, {}, {});
  }
  delete(month: string): Promise<any> {
    return httpService.delete(`automaticData/${month}`, {}, {});
  }
}

export const automaticDataFetcherService = new AutomaticDataFetcherService();
