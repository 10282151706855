import { httpService } from '@/services/HttpService';

class DataSourcesService {
  saveBasicConfiguration(apiUrl: string, token: string): Promise<any> {
    return httpService.post('dataSource/configuration', { apiUrl, token }, {});
  }
  getBasicConfiguration(): Promise<any> {
    return httpService.get('dataSource/configuration', {}, {});
  }
}

export const dataSourcesService = new DataSourcesService();
