







































import { Component, Vue } from 'vue-property-decorator';
import LoadingBar from '@/components/common/LoadingBar.vue';
import ErrorBar from '@/components/common/ErrorBar.vue';
import { automaticDataFetcherService } from '@/services/AutomaticDataFetcherService';
import Popup from '@/components/common/Popup.vue';

@Component({
  components: { ErrorBar, LoadingBar, Popup },
})
export default class DataFetcher extends Vue {
  data: any = [];
  isFetching = false;
  isLoading = true;
  hasError = false;
  itemsPerMonth = 0;
  isPopup = false;
  monthToRemove = '';

  cancelPopup() {
    this.isPopup = false;
  }

  confirmRemove() {
    this.isPopup = false;
    automaticDataFetcherService.delete(this.monthToRemove).then(() => {
      this.getList();
    });
  }

  remove(month: string) {
    this.monthToRemove = month;
    this.isPopup = true;
  }

  downloadData(item: any, index: number, isContinuation = false) {
    if (this.isFetching && !isContinuation) {
      return;
    }
    this.isFetching = true;
    automaticDataFetcherService
      .fetch(item.month)
      .then((data: any) => {
        if (data.finished) {
          this.isFetching = false;
        } else {
          this.downloadData(item, index, true);
        }
        this.data[index].state = data.state;
        this.data = [...this.data];
      })
      .catch(() => {
        this.isFetching = false;
      });
  }
  mounted() {
    this.getList();
  }
  getList() {
    this.isLoading = true;
    automaticDataFetcherService.list().then((data: any) => {
      this.isLoading = false;
      this.data = data.items;
      this.itemsPerMonth = data.max;
    });
  }
}
